import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { Timeline } from '../Upload/utils/upload.types';
import React, { useMemo } from 'react';
interface EmotionData {
  neutral: number;
  anger: number;
  fear: number;
  surprise: number;
  sadness: number;
  disgust: number;
  joy: number;
}

interface EmotionsReportPDFProps {
  datas: Timeline;
}

const styles = StyleSheet.create({
  page: { 
    padding: 24,
    backgroundColor: '#ffffff'
  },
  header: { 
    fontSize: 24, 
    marginBottom: 20,
    color: '#2B3674',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  section: { 
    marginBottom: 16,
    padding: 12,
    backgroundColor: '#F7F9FC',
    borderRadius: 4,
  },
  timeContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
    padding: 8,
    backgroundColor: '#EDF2F7',
    borderRadius: 4,
  },
  timeLabel: {
    fontSize: 10,
    color: '#718096',
  },
  timeValue: {
    fontSize: 10,
    color: '#2D3748',
    fontWeight: 'bold',
  },
  emotionsHeader: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#4A5568',
    marginBottom: 8,
  },
  emotionRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 4,
    paddingVertical: 2,
  },
  emotionLabel: {
    fontSize: 10,
    color: '#4A5568',
    width: '30%',
  },
  emotionBarContainer: {
    flex: 1,
    height: 6,
    backgroundColor: '#E2E8F0',
    borderRadius: 3,
    marginHorizontal: 8,
  },
  emotionBarFill: {
    height: '100%',
    borderRadius: 3,
  },
  emotionValue: {
    fontSize: 10,
    color: '#2D3748',
    width: '15%',
    textAlign: 'right',
  },
  dominantEmotion: {
    backgroundColor: '#E6FFFA',
    padding: 8,
    borderRadius: 4,
    marginBottom: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dominantEmotionLabel: {
    fontSize: 11,
    color: '#2C7A7B',
    fontWeight: 'bold',
  },
  dominantEmotionValue: {
    fontSize: 11,
    color: '#2C7A7B',
    fontWeight: 'bold',
  },
  confidenceSection: {
    backgroundColor: '#F0FFF4',
    padding: 8,
    borderRadius: 4,
    marginBottom: 12,
  },
  confidenceHeader: {
    fontSize: 12,
    color: '#2F855A',
    fontWeight: 'bold',
    marginBottom: 4,
  },
  confidenceBar: {
    height: 8,
    backgroundColor: '#E2E8F0',
    borderRadius: 4,
    marginTop: 4,
  },
  confidenceFill: {
    height: '100%',
    backgroundColor: '#48BB78',
    borderRadius: 4,
  },
  confidenceValue: {
    fontSize: 10,
    color: '#2F855A',
    marginTop: 2,
    textAlign: 'right',
  },
  emotionRowHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 8,
    marginBottom: 4,
  },
  headerLabel: {
    fontSize: 9,
    color: '#718096',
    width: '30%',
  },
  headerValue: {
    fontSize: 9,
    color: '#718096',
    width: '15%',
    textAlign: 'right',
  }
});

const getEmotionColor = (emotion: string): string => {
  const colors: Record<string, string> = {
    anger: '#FC8181',
    disgust: '#9AE6B4',
    fear: '#B794F4',
    joy: '#FFD700',
    neutral: '#A0AEC0',
    sadness: '#63B3ED',
    surprise: '#F6AD55'
  };
  return colors[emotion] || '#A0AEC0';
};


const EmotionsReportPDF: React.FC<EmotionsReportPDFProps> = ({ datas }) => {


  return (
    <Document>
    <Page style={styles.page}>
      <Text style={styles.header}>Emotion Analysis Report</Text>
      
      {datas.map((entry, index) => (
        <View key={index} style={styles.section}>
          <View style={styles.timeContainer}>
            <View>
              <Text style={styles.timeLabel}>Start Time</Text>
              <Text style={styles.timeValue}>{entry.start_time}</Text>
            </View>
            <View>
              <Text style={styles.timeLabel}>End Time</Text>
              <Text style={styles.timeValue}>{entry.end_time}</Text>
            </View>
          </View>

          {/* Overall Confidence Section */}
          <View style={styles.confidenceSection}>
            <Text style={styles.confidenceHeader}>Overall Confidence</Text>
            <View style={styles.confidenceBar}>
              <View 
                style={[
                  styles.confidenceFill,
                  { width: `${entry.confidence * 100}%` }
                ]}
              />
            </View>
            <Text style={styles.confidenceValue}>
              {(entry.confidence * 100).toFixed(1)}%
            </Text>
          </View>

          <View style={styles.dominantEmotion}>
            <Text style={styles.dominantEmotionLabel}>
              Dominant: {entry.dominant_emotion}
            </Text>
            <Text style={styles.dominantEmotionValue}>
              {(entry.confidence * 100).toFixed(1)}%
            </Text>
          </View>

          <Text style={styles.emotionsHeader}>Emotional Analysis</Text>
          
          {/* Column Headers */}
          <View style={styles.emotionRowHeader}>
            <Text style={styles.headerLabel}>Emotion</Text>
            <Text style={[styles.headerLabel, { flex: 1, textAlign: 'center' }]}>Intensity</Text>
            <Text style={styles.headerValue}>Value</Text>
          </View>

          {(Object.entries(entry.emotions) as [keyof EmotionData, number][])
            .sort(([, a], [, b]) => b - a)
            .map(([emotion, value]) => (
              <View key={emotion} style={styles.emotionRow}>
                <Text style={styles.emotionLabel}>
                  {emotion.charAt(0).toUpperCase() + emotion.slice(1)}
                </Text>
                <View style={styles.emotionBarContainer}>
                  <View 
                    style={[
                      styles.emotionBarFill, 
                      { 
                        width: `${value * 100}%`,
                        backgroundColor: getEmotionColor(emotion)
                      }
                    ]} 
                  />
                </View>
                <Text style={styles.emotionValue}>
                  {(value * 100).toFixed(1)}%
                </Text>
              </View>
            ))}
        </View>
      ))}
    </Page>
  </Document>

  );
};

export default React.memo( EmotionsReportPDF);