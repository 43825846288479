import { Timeline } from '../Components/Upload/utils/upload.types'; // Adjust the import path according to your project structure

// Helper function to format time by replacing comma with period
 const formatStartOrEndTime = (time: string): string => {
  return time.replace(',', '.'); // Convert "00:06:07,299" to "00:06:07.299"
};

// Helper function to format numbers to fixed decimal places
const formatNumber = (num: number): string => {
  return num.toFixed(4);
};

// Convert timeline data to CSV format
 const convertToCSV = (data: Timeline): string => {
  // CSV Headers without text field
  const headers = [
    'Start Time',
    'End Time',
    'Dominant Emotion',
    'Confidence',
    'Anger',
    'Disgust',
    'Fear',
    'Joy',
    'Neutral',
    'Sadness',
    'Surprise'
  ];

  // Create header row
  const headerRow = headers.join(',');
  
  // Create data rows without text field
  const rows = data.map(entry => {
    const values = [
      formatStartOrEndTime(entry.start_time),
      formatStartOrEndTime(entry.end_time),
      entry.dominant_emotion,
      formatNumber(entry.confidence),
      formatNumber(entry.emotions.anger),
      formatNumber(entry.emotions.disgust),
      formatNumber(entry.emotions.fear),
      formatNumber(entry.emotions.joy),
      formatNumber(entry.emotions.neutral),
      formatNumber(entry.emotions.sadness),
      formatNumber(entry.emotions.surprise)
    ];
    return values.join(',');
  });

  return [headerRow, ...rows].join('\n');
};

// Function to trigger CSV download
export const downloadCSV = (data: Timeline,name:string): void => {
  const BOM = '\uFEFF';
  const csv = BOM + convertToCSV(data);
  
  const blob = new Blob([csv], { 
    type: 'text/csv;charset=utf-8;' 
  });
  
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', `${name}.csv`);
  
  document.body.appendChild(link);
  link.click();
  link.remove();
  URL.revokeObjectURL(url);
};