import React, { useMemo } from 'react';
import {
    AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
} from 'recharts';
import happyFace from '../../assets/Happy.png';
import surpriseFace from '../../assets/Flushed Face.png';
import sadFace from '../../assets/Pensive Face.png';
import staticFace from '../../assets/Neutral.png';
import angryFace from '../../assets/AngryFace.png';
import ellipse from '../../assets/Ellipse 1989.svg';
import fearFace from '../../assets/fearful-face.png';
import disgustedFace from '../../assets/nauseated-face.png'
import { Timeline } from '../Upload/utils/upload.types';

const sliderData = [
    {
      emojiSrc: angryFace, // replace with your emoji image paths
      color: "bg-red-500", // darker gray for inactive states
    },
    {
      emojiSrc: sadFace,
      color: "bg-orange-400", // lighter gray
    },
    {
      emojiSrc: staticFace,
      color: "bg-yellow-400", // even lighter gray
    },
    {
        emojiSrc: happyFace,
        color: "bg-green-400", // another gray
      },
    {
      emojiSrc: surpriseFace,
      color: "bg-green-500", // green for the active/highlighted state
    },
    
  
  ];
  interface HeatMapProps{
    datas:Timeline
  }
const EmotionChartAnalysis: React.FC<HeatMapProps> = ({datas}) => {
    const emotionImages = [angryFace,happyFace,staticFace, sadFace, surpriseFace,disgustedFace, fearFace,   ];
    // Custom Y-axis tick rendering
    const emotionLabels = [
        'Anger',
        'Joy',
        'Neutral',
        'Sadness',
        'Surprise',
        'Disgust',
        'Fear',   
    ];
    const convertTimeToMinutes = (timeStr:string) => {
        const [h, m, s] = timeStr.split(':');
        const seconds = parseFloat(s.replace(',', '.')); // Replace the comma and convert to float
       return parseFloat((parseInt(h, 10) * 60 + parseInt(m, 10) + seconds / 60).toFixed(1)); // Convert to total minutes
    };
    
    const getEmotionIndex = (item:any) => {
        // Replace the following conditions with your actual logic for determining the emotion
        if (item.dominant_emotion == 'anger') return 0; // Anger
        if (item.dominant_emotion == 'joy') return 1; // Sadness
        if (item.dominant_emotion == 'neutral') return 2; // Neutral
        if (item.dominant_emotion == 'sadness') return 3; // Joy
        if (item.dominant_emotion == 'surprise') return 4; // Surprise
        if (item.dominant_emotion == 'disgust') return 5; // Disgust
        if (item.dominant_emotion == 'fear') return 6; // Fear
        return 2; 
    };
    
    const formattedData = useMemo(() => {
        return datas.map(item => ({
            ...item,
            startingTime: item.start_time,
            start_time: convertTimeToMinutes(item.start_time), // Call directly
            emotionIndex: getEmotionIndex(item), // Call directly
        }));
    }, [datas])
    // Custom Y-axis tick rendering with emoji and custom text
    const renderCustomYAxisTick = (tickProps: any) => {
        const { x, y, payload } = tickProps;
        const imageIndex = payload.value; // Use the emotion value to find the image
        const imgSrc = emotionImages[imageIndex];
        const imageSize = 30; // Set a fixed image size
        const leftOffset = 25; // Adjust this value to move the emoji to the left
        const textYOffset = 10; // Vertical space between emoji and text

        // Adjusted positioning for moving to the left and centering vertically
        return (
            <g transform={`translate(${x - imageSize / 2 - leftOffset}, ${y})`}>
                {/* Emoji */}
                <image
                    href={imgSrc}
                    width={imageSize}
                    height={imageSize}
                    y={-imageSize / 2} // Center the emoji vertically
                />
                {/* Text below the emoji */}
                <text
                    x={imageSize / 2} // Align the text in the center
                    y={imageSize / 2 + textYOffset} // Position text below emoji
                    fontSize={12} // Adjust text size
                    textAnchor="middle" // Center-align the text horizontally
                    fill="#333" // Text color
                >
                    {emotionLabels[payload.value]} {/* Display custom emotion label */}
                </text>
            </g>
        );
    };
 
    // Example data transformation
    
    const renderCustomXAxisTick = (tickProps: any) => {
        const { x, y, payload } = tickProps;

        return (
            <g transform={`translate(${x}, ${y + 20})`}> {/* Move down by 20 units */}
                <text
                    x={0} // Positioning the text at the tick position
                    y={0} // Use the adjusted y position
                    fontSize={14} // Set the font size for better visibility
                    textAnchor="middle" // Center the text horizontally
                    fill="#333" // Text color
                >
                    {payload.value} {/* Display the time value */}
                </text>
            </g>
        );
    };


    return (
        <div className="w-full p-4">
            <h2 className="text-xl font-bold mt-12 mb-4 text-left">Heatmap preview</h2>
            <ResponsiveContainer width="100%" height={500}>
                <AreaChart data={formattedData} margin={{ top: 20, bottom: 20, left: 0, right: 6 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="start_time" tick={renderCustomXAxisTick}  label={{ value: "Time (mins)", position: "insideBottom", offset: -18 }}  />
                    <YAxis
                        tick={renderCustomYAxisTick} // Use the custom tick renderer
                        domain={[0, 6]} // Emojis from index 0 to 7
                        ticks={[0, 1, 2, 3, 4, 5, 6,]}
                       
                        
                    />
                    <Tooltip
                        
                        formatter={(value: number, name: string, props: any) => {
                            if (props.payload) {
                                const { dominant_emotion,emotionIndex } = props.payload; // Access the first payload
                                return [
                                    <div className="flex items-center">
                                        <img src={emotionImages[emotionIndex]} alt={dominant_emotion} className="w-6 h-6" /> {/* Display the emoji */}
                                    </div>,
                                    "Emotion",
                                ];
                            }
                            return [value, name]; // Fallback in case of unexpected structure
                        }}
                    />
                    <Area
                        type="monotone"
                        dataKey="emotionIndex"
                        stroke="#8884d8"
                        fill="url(#colorEmotions)"
                    />
                    <defs>
                        <linearGradient id="colorEmotions" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#ff0000" stopOpacity={0.8} /> {/* Happy */}
                            <stop offset="95%" stopColor="#00ff00" stopOpacity={0.8} /> {/* sadFace */}
                        </linearGradient>
                    </defs>
                </AreaChart>
            </ResponsiveContainer>
            <div className="flex justify-center space-x-6 mt-8">
      {sliderData.map((item, index) => (
        <div key={index} className="flex flex-col items-center">
          <img
            src={item.emojiSrc}
            alt="emotion"
            className="w-8 h-8 mb-2"
          />
          <div
            className={`w-12 h-4 rounded-full ${item.color}`} 
          />
        </div>
      ))}
    </div>
            <div className="absolute mt-12 left-0 right-0 w-full h-0.5 bg-slate-200"></div>
            <h2 className="text-xl font-bold mt-24 mb-4 text-left">Emotion Analysis</h2>
            <div className="mt-6">
                <ul className="space-y-3">
                    {formattedData.map((item, index) => (
                        <li key={index} className="flex items-center bg-slate-200 p-1 rounded-md">
                            <img src={ellipse} alt="green dot" className="w-2 h-2 mr-3" />
                            <div className="">{item.startingTime} - {item.end_time}-{item.dominant_emotion}</div>
                            <img src={emotionImages[item.emotionIndex]} alt="emotion" className="w-6 h-6 ml-3" />
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default React.memo( EmotionChartAnalysis);
